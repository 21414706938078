import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Text from "@ui/text";
import Heading from "@ui/heading";
import Image from "@ui/image";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import Tabs, { Tab } from "@ui/tabs";
import List, { ListItem } from "@ui/list";
import Button from "@ui/button";
import {
  TabsSectionWrapper,
  TabsSectionInner,
  TabsWrapper,
  TabsBottomImgWrap,
  ButtonWrap,
} from "./style";

const TabsSection = ({ data, layout, ...props }) => {
  return (
    <TabsSectionWrapper id={data.section} layout={layout} {...props}>
      <TabsSectionInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                subtitle={data.section_title?.subtitle}
                title={data.section_title?.title}
                description={data.section_title?.description}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <TabsWrapper>
                <Tabs  layout={2}>
                  {data &&
                    data.items.map((el, index) => (
                      <Tab eventKey={el.title} title={el.title} key={index}>
                        {el.texts && el.texts[0].content && (
                          <Heading as="h4" mb="25px">
                            {el.texts[0]?.content}
                          </Heading>
                        )}
                        {el && el.description && (
                          <Text
                            dangerouslySetInnerHTML={{
                              __html: el.description,
                            }}
                          ></Text>
                        )}

                        <List $layout="circle">
                          {!!el?.list?.length &&
                            el.list.map((el , index) => (
                              <ListItem
                                dangerouslySetInnerHTML={{ __html: el.text }}
                                key={index}
                              ></ListItem>
                            ))}
                        </List>
                        {!!el.path && (
                          <ButtonWrap>
                            <Button
                              path={el.path}
                              color="white"
                              fontWeight={400}
                              icondistance="8px"
                              align="center"
                            >
                              {el.name}
                            </Button>
                          </ButtonWrap>
                        )}
                      </Tab>
                    ))}
                </Tabs>
              </TabsWrapper>
            </Col>
            {!!data.images && (
              <Col lg={12}>
                <TabsBottomImgWrap>
                  <Image
                    src={data.images[0]?.src}
                    alt={data.images[0]?.alt || "Image"}
                  />
                </TabsBottomImgWrap>
              </Col>
            )}
          </Row>
        </Container>
      </TabsSectionInner>
    </TabsSectionWrapper>
  );
};

TabsSection.propTypes = {
  layout: PropTypes.oneOf([1, 2]),
};
TabsSection.defaultProps = {
  layout: 1,
};

export default TabsSection;
