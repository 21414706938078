import styled, { themeGet, css, device } from "@styled";
import { fadeInDown } from "@assets/css/animations";

export const StickyWrapper = styled.section`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 99999;
  top: 90px;
  display: none;
  transition: ${themeGet("transition")};
  ${(props) =>
    props.isStickyMenu &&
    css`
      display: none;
      ${device.xlarge} {
        display: block;
        animation: 0.95s ease-in-out 0s normal none 1 running ${fadeInDown};
      }
    `}
`;
export const StickyInner = styled.div``;
export const StickyListWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StickyList = styled.div`
  background: ${themeGet("colors.primary")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 50px;
  ${device.xxlarge} {
    padding: 10px;
  }
`;
export const StickyItem = styled.div`
  &.active {
    a {
      background: #f8b69d;
      color: #000;
    }
  }
  a {
    color: #fff;
    font-size: 16px;
    padding: 5px 15px;
    border-radius: 50px;
    background: none;
    white-space: nowrap;
    &:hover {
      background: #f8b69d;
      color: #000;
    }
    ${device.xxlarge} {
      font-size: 18px;
      padding: 10px 20px;
    }
  }
`;
