import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import { HeadingType, TextType } from "@utils/types";
import { useSticky } from "@hooks";
import {
  StickyWrapper,
  StickyInner,
  StickyListWrap,
  StickyList,
  StickyItem,
} from "./style";
import { Anchor } from "react-bootstrap";
import { useEffect } from "react";

const StickyMenu = ({ data }) => {
  const { sticky, headerRef, fixedRef } = useSticky();
  const tabActive = (e , id) => {
    var el = document.querySelectorAll(".listItem");
    for (let i = 0; i < el.length; i++) {
      el[i].classList.remove("active");
    }
    e.currentTarget.classList.add("active");
    document
      .getElementById(id)
      .scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
  };

 useEffect(()=>{
  
 },[])
  return (
    <StickyWrapper ref={fixedRef} isStickyMenu={sticky}>
      <StickyInner ref={headerRef}>
        <Container>
          <Row>
            <Col lg={12}>
              <StickyListWrap>
                <StickyList>
                  {data &&
                    data?.items?.map((el, index) => (
                      <StickyItem
                        className="listItem"
                        key={index}
                        onClick={(e) => tabActive(e, el.path)}
                      >
                        <Anchor>{el.title}</Anchor>
                      </StickyItem>
                    ))}
                </StickyList>
              </StickyListWrap>
            </Col>
          </Row>
        </Container>
      </StickyInner>
    </StickyWrapper>
  );
};

StickyMenu.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
  }),
};

export default StickyMenu;
