import styled, { css, themeGet, device } from "@styled";
import orangewaveBg from "@data/images/bg/orangewaveBg.webp";

export const TabsSectionWrapper = styled.section`
  padding-top: 180px;
  padding-bottom: 120px;
  position: relative;
  overflow: hidden;
  background: url(${orangewaveBg});
  background-size: cover;
  background-position: left top;
  background-color: transparent;
  ${(props) =>
    props.layout === 1 &&
    css`
      background: url(${orangewaveBg});
      background-size: cover;
      background-position: left top;
      background-color: transparent;
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      background: linear-gradient(
        320deg,
        ${themeGet("colors.primaryDark")},
        ${themeGet("colors.primary")}
      );
    `}
  .section-title {
    h6 {
      color: #fff;
    }
    h2 {
      border-color: #fff;
      span {
        color: #fff;
      }
    }
    p {
      color: #fff;
    }
  }
`;
export const TabsSectionInner = styled.div``;
export const TabsWrapper = styled.div`
  background: #ff6d39;
  border-radius: 10px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${device.medium} {
    padding: 30px 50px 50px;
  }
  .tab-content {
    width: 100%;
    ul {
      li {
        color: #fff;
        &::before {
          background: #fff;
        }
      }
    }
  }
  h4 {
    font-size: 17px;
    font-weight: bold;
    color: #fff;
  }
  p {
    font-size: 17px;
    color: #fff;
  }
`;
export const TabsBottomImgWrap = styled.div`
  margin-bottom: -120px;
  margin-top: 80px;
  text-align: center;
`;
export const ButtonWrap = styled.div`
  text-align: center;
`;
